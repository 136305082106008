.headerFont {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.toggle.react-toggle--checked .react-toggle-track {
    background-color: darkgray;
}

.toggle .react-toggle-track {
    background-color: lightyellow;
}

.toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.headerStyles {
    max-width: 100vw;
    min-height: 70px;
}

.navbar-nav a {
    margin-left: 3vw;
}
