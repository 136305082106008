.blog-page-header {
    margin-bottom: 2vh;
    margin-top: 2vh;
    width: 100%;
    text-align: center;
}

.blog-page {
    padding: 3em;
}
