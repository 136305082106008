@import url('https://fonts.googleapis.com/css?family=Inconsolata:200,400,700|Montserrat:200,400,600&display=swap');

h1, h2, h3, h4, h5, h6 {
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
}

.title {
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
}

body, p, span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

i {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
}
