button {
    margin-left: 1em;
}

.footer {
    bottom: 0;
    text-align: center;
    position: fixed;
    display: block;
    padding: 1rem;
    width: 100vw;
}

.footer.light {
    background-color: rgb(248, 249, 250);
}

.footer.dark {
    background-color: rgb(52, 58, 64);
}
