.about-section:not(.cover) {
    min-height: 200px;
    padding-top: 20px;
}

.cover {
    background-image: url(../../assets/cover-optimized.jpg);
    background-attachment: fixed;
    align-content: center;
    text-align: center;
    background-position: top;
    height: 80vh;
    max-height: 700px;
}

.cover-header {
    color: black;
    background-color: rgba(230, 230, 230, .75);
}

.cover-header.dark {
    color: whitesmoke;
    background-color: rgba(10, 10, 10, .75);
}

.cover-header h1 {
    font-size: 5rem;
    font-family: 'Inconsolata', sans-serif;
    font-style: normal;
}

.cover-header h3 {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.resume-button {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px
}

.resume-page {
    width: 100%;
    overflow: auto;
}

.resume-doc {
    width: 100%;
}

.resume-modal {
    width: 100%;
    max-height: 95%;
}

.modal-dialog {
    width: 750px;
    max-width: 80vw;
}

.modal-body {
    min-height: 85vh;
}

@media only screen and (max-width: 600px) {
    .modal-dialog {
        width: 100%;
        max-width: 100vw;
        padding: 0;
        margin: 0;
        height: 100vh;
        max-height: 100vh;
    }
}

.certifications {
    min-height: 20vh;
    margin-bottom: 20px;
}

.certifications img {
    max-width: 50%;
    max-height: 200px;
}

.job h4 {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.job h6 {
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
}


.about-column {
    padding-right: 50px;
    padding-left: 50px;
}

.page.dark {
    background-color: rgba(10, 10, 10, .75);
    color: whitesmoke;
}

.page {
    background-color: rgba(230, 230, 230, .75);
}

ul {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.center {
    text-align: center;
    padding-bottom: 50px;
}
